import { createI18n } from "vue-i18n/dist/vue-i18n.esm-bundler.js";
import store from "@/store";

const i18n = createI18n({
  locale: store.state.system.locale || "en", //默认显示的语言
  fallbackLocale: "en", //当切换语言时,显示的语言
  messages: {
    en: require("./locales/en.json"),
    zh: require("./locales/zh.json"), // 简体中文
    hk: require("./locales/hk.json"), // 简体中文
    id: require("./locales/id.json"), // 印尼语
    th: require("./locales/th.json"), // 泰文
  },
});

export default i18n;
