const getters = {
  languageType: (state) => state.system.languageType,
  locale: (state) => state.system.locale,
  theme: (state) => state.system.theme,
  token: (state) => state.system.token,
  returnData: (state) => state.transfer.returnData,
  currentPageData: (state) => {
    return function(path) {
      if (!path) {
        return state.transfer.currentPageData[path];
      } else {
        return null;
      }
    };
  },
  submitLock: (state) => {
    return function(path) {
      // console.log('path', path);
      if (!path) {
        return !!state.locker.submit;
      }
      return path === state.locker.submit;
    };
  },
};
export default getters;