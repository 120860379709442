import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed, onMounted, inject } from "vue";
import { useStore } from "vuex";
import en from "element-plus/es/locale/lang/en";
import Location from "@/utils/location";
export default {
  __name: 'App',

  setup(__props) {
    const $store = useStore();
    const $storage = inject("$storage");
    const locale = computed(() => $store.getters.locale === "en" ? en : null);
    onMounted(() => {
      const localStyle = $storage.get("themeStyle") || "light";
      $storage.set("themeStyle", localStyle);
      $store.dispatch("system/changeTheme", localStyle);
      const localLanguage = Location.getLocale();
      $store.dispatch("system/changeLocale", localLanguage);
      document.getElementsByTagName("body")[0].className = localStyle + "-mode " + localLanguage;
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      const _component_el_config_provider = _resolveComponent("el-config-provider");

      return _openBlock(), _createBlock(_component_el_config_provider, {
        locale: locale.value
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      }, 8, ["locale"]);
    };
  }

};