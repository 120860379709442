import { createRouter, createWebHistory } from "vue-router";
import { getPageTitle } from "@/utils/page";
import VueI18n from "@/language";

const routes = [
  {
    path: "/",
    name: "Start",
    redirect: "/property-details",
  },
  {
    path: "/summary",
    name: "Summary",
    component: () => import("@/views/Summary.vue"),
  },
  {
    path: "/unsubscribe-success",
    name: "UnsubscribeSuccess",
    component: () => import("@/views/UnsubscribeSuccess.vue"),
  },
  {
    path: "/subscribe-success",
    name: "SubscribeSuccess",
    component: () => import("@/views/SubscribeSuccess.vue"),
  },
  {
    path: "/request-info-success",
    name: "RequestInfoSuccess",
    component: () => import("@/views/RequestInfoSuccess.vue"),
  },
  {
    path: "/book-inspection",
    name: "BookInspection",
    component: () => import("@/views/BookInspection.vue"),
  },
  {
    path: "/book-inspection-success",
    name: "BookInspectionSuccess",
    component: () => import("@/views/BookInspectionSuccess.vue"),
  },
  {
    path: "/sign-sales-advice",
    name: "SignSalesAdvice",
    component: () => import("@/views/SignSalesAdvice.vue"),
  },
  {
    path: "/property-details",
    name: "PropertyDetails",
    component: () => import("@/views/PropertyDetials.vue"),
  },
  {
    path: "/short-survey",
    name: "ShortSurvey",
    component: () => import("@/views/short_survey/Index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 如果有保存的位置，滚动到保存的位置
    if (savedPosition) {
      return savedPosition;
    } else {
      // 滚动到页面顶部
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  setMeta(to.meta);
  next();
});

const setMeta = (meta) => {
  if (meta.title) {
    document.title = getPageTitle(VueI18n.global.t(meta.title));
  }
};

export default router;
