const state = {
  returnData: {}, // 页面回退时的返回值 
  currentPageData: [], // 当前页面临时保存的值
  from: {},
  to: {},
};

const mutations = {
  RETURN_DATA(state, obj) {
    state.returnData = obj;
  },
  CURRENT_PAGE_DATA(state, obj) {
    state.currentPageData = obj;
  },
};

const actions = {
  setReturnData({ commit }, params) {
    return new Promise((resolve) => {
      commit("RETURN_DATA", params);
      resolve();
    });
  },
  setCurrentPageData({ commit }, params) {
    return new Promise((resolve) => {
      var data = state.currentPageData;
      data[params.path] = params.data;
      commit("CURRENT_PAGE_DATA", data);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
