import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  class: "dialog-footer"
};
import { onMounted, ref, watch } from "vue";
import VueI18n from "@/language";
export default /*@__PURE__*/Object.assign({
  name: 'MessageBox'
}, {
  __name: 'MessageBox',
  props: {
    message: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "info"
    },
    // prompt, info, warning, error
    options: {
      type: Object
    }
  },
  emits: ["cancel", "confirm", "update:visible"],

  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const closeOnClick = ref(true);
    const closeOnEsc = ref(true);
    const draggable = ref(true);
    const dialogVisible = ref(false);
    const data = ref({
      width: "420px",
      dangerouslyUseHTMLString: false,
      confirmButtonText: VueI18n.global.t('confirm'),
      cancelButtonText: VueI18n.global.t('cancel'),
      okButtonText: VueI18n.global.t('ok')
    });
    onMounted(() => {
      setOptionData();
      dialogVisible.value = props.visible;
    });
    watch(() => props.visible, (visible, preVisible) => {
      if (visible) {
        setOptionData();
      }

      dialogVisible.value = visible;
    });

    const setOptionData = () => {
      data.value = { ...data.value,
        ...props.options
      };
    };

    const handleClose = () => {
      emit("update:visible");
    };

    const handleCancel = () => {
      emit("cancel");
    };

    const handleConfirm = () => {
      emit("confirm");
    };

    return (_ctx, _cache) => {
      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => dialogVisible.value = $event),
        title: __props.title,
        width: data.value.width,
        draggable: draggable.value,
        "close-on-click-modal": closeOnClick.value,
        "close-on-press-escape": closeOnEsc.value,
        "before-close": handleClose
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_3, [__props.type === 'warning' ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "button secondard",
          onClick: handleCancel
        }, _toDisplayString(data.value.cancelButtonText), 1)) : _createCommentVNode("", true), __props.type === 'info' || __props.type === 'error' ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: "button",
          onClick: handleConfirm
        }, _toDisplayString(data.value.okButtonText), 1)) : (_openBlock(), _createElementBlock("span", {
          key: 2,
          class: "button",
          onClick: handleConfirm
        }, _toDisplayString(data.value.confirmButtonText), 1))])]),
        default: _withCtx(() => [data.value.dangerouslyUseHTMLString ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          innerHTML: __props.message
        }, null, 8, _hoisted_1)) : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.message), 1))]),
        _: 1
      }, 8, ["modelValue", "title", "width", "draggable", "close-on-click-modal", "close-on-press-escape"]);
    };
  }

});