import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["xlink:href"];
import { computed } from "vue"; // 定义 props

export default {
  __name: 'index',
  props: {
    name: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ""
    }
  },

  setup(__props) {
    const props = __props; // 计算属性

    const svgClass = computed(() => {
      return props.className ? `svg-icon ${props.className}` : "svg-icon";
    });
    const iconName = computed(() => {
      return `#icon-${props.name}`;
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("svg", {
        class: _normalizeClass(svgClass.value),
        "aria-hidden": "true"
      }, [_createElementVNode("use", {
        "xlink:href": iconName.value
      }, null, 8, _hoisted_1)], 2);
    };
  }

};