import { getTs } from "./time";

class Storage {
  constructor() {
    this.store = window.localStorage;
    this.session = window.sessionStorage;
    this.prefix = "Arcanite_";
  }

  // 存储单一的值
  set(key, value, useSession=false) {
    if (useSession) {
      this.session.setItem(this.prefix + key, value);
    } else {
      this.store.setItem(this.prefix + key, value);
    }
  }

  // 存储JSON对象值
  setJSON(key, value, useSession=false) {
    const storedValue = (typeof value === "object" ? JSON.stringify(value) : value);
    this.set(key, storedValue, useSession);
  }

  // 获取单一的值
  get(key, useSession=false) {
    if (!key) {
      throw new Error("Key not provided");
    }
    if (typeof key === "object") {
      throw new Error("Key should not be an object");
    }
    
    if (useSession) {
      return this.session.getItem(this.prefix + key);
    } else {
      return this.store.getItem(this.prefix + key);
    }
  }

  // 获取数值
  getNumber(key, useSession=false) {
    return Number(this.get(key, useSession));
  }

  // 获取JSON对象值
  getJSON(key, useSession=false) {
    return JSON.parse(this.get(key, useSession));
  }

  remove(key, useSession=false) {
    if (useSession) {
      this.session.removeItem(this.prefix + key);
    } else {
      this.store.removeItem(this.prefix + key);
    }
  }

  // 以Map的形式保存数据
  push(key, mapKey, value, useSession=false) {
    var jsonData = this.getJSON(key, useSession);
    var mapData = jsonData ? new Map(jsonData) : new Map();
    if (value) {
      mapData.set(mapKey, 
        (typeof value === "object" ? JSON.stringify(value) : value)
      );
    } else {
      mapData.delete(mapKey);
    }
    this.setJSON(key, Array.from(mapData), useSession);
  }

  // 从Map的形式数据中获取数据
  pull(key, mapKey, useSession=false) {
    var jsonData = this.getJSON(key, useSession);
    var mapData = jsonData ? new Map(jsonData) : new Map();
    try {
      return JSON.parse(mapData.get(mapKey));
    } catch {
      return mapData.get(mapKey);
    }
  }
}

export default new Storage();
