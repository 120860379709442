import moment from "moment";
import timezone from "moment-timezone";

// 将给定的日期格式化为指定的日期时间格式，并返回格式化后的字符串
export function formatDate(date, fmt = 'YYYY-MM-DD HH:mm:ss') {
  if (!date) {
    return moment().format(fmt);
  } else {
    return moment(date).format(fmt);
  }
}

// 将 指定格式转换为 Date 对象
export function parseDate(strDate, fmt = "YYYY-MM-DD HH:mm:ss") {
  if (strDate) {
    return moment(strDate, fmt).toDate();
  } else {
    return strDate
  }
};

// ios 兼容 
// 将一个表示日期的字符串转换为 JavaScript 的 Date 对象
export function toDate(strDate) {
  if (strDate) {
    return new Date(strDate.replace(/\-/g,"/"));
  } else {
    return strDate;
  }
}

// 将给定的以秒为单位的时间戳格式化为指定的日期时间格式，并返回格式化后的字符串
export function formatSecond(seconds, fmt = 'YYYY-MM-DD HH:mm:ss') {
  return moment.unix(seconds).format(fmt);
}

// 获取指定日期（或当前日期）的 UNIX 时间戳（以秒为单位），
// 其中 UNIX 时间戳是指从 1970年1月1日 00:00:00 UTC 到指定日期时间的秒数
export function getSeconds(date) {
  if (!date) {
    return moment().unix();  
  } else {
    return moment(date).unix();
  }
}

// 获取指定日期（或当前日期）的 时间戳（以毫秒为单位），
// 时间戳是指从 1970年1月1日 00:00:00 UTC 到指定日期时间的毫秒数
export function getTs(date) {
  if (!date) {
    return moment().valueOf();
  } else {
    return moment(date).valueOf();
  }
}

// 当前的本地时间，并以指定的格式返回
export function getNow(fmt = 'YYYY-MM-DD HH:mm:ss') {
  return moment().format(fmt);
}

// 获取当前的 UTC 时间，并以指定的格式返回
export function getNowUtc(fmt = 'YYYY-MM-DD HH:mm:ss') {
  return moment.utc().format(fmt);
}

// 将给定的 UTC 时间转换为本地时间，并以指定的格式返回
export function utcToLocal(date, fmt = 'YYYY-MM-DD HH:mm:ss') {
  return moment.utc(date).local().format(fmt);
}

// 将给定的本地时间转换为 UTC 时间，并以指定的格式返回
export function localToUtc(date, fmt = 'yyyy-MM-DD HH:mm:ss') {
  if (date) {
    return moment(date, fmt).utc().format(fmt);
  } else {
    return null;
  }
}

// 将给定的 UTC 时间转换为本地时间，并返回对应的时间戳（以毫秒为单位）
export function utcToLocalTs(date) {
  return moment.utc(date).local().valueOf();
}

export function diffUtc(date, date2, unit) {
  return moment.utc(date).diff(moment.utc(date2), unit);
}

export function diff(date, date2, unit) {
  return moment(date).diff(moment(date2), unit);
}

export function getTimeZone(){
  var offset = 0 - new Date().getTimezoneOffset();
  // 计算小时和分钟部分
  var hours = Math.abs(Math.floor(offset / 60));
  hours = (hours < 10 ? '0' : '') + hours;
  var minutes = Math.abs(offset % 60);
  minutes = (minutes < 10 ? '0' : '') + minutes;
  
  // 确定时区方向
  var sign = (offset < 0) ? "-" : "+";
  
  // 格式化为 "+hh:mm" 的字符串格式
  var formattedTimeZone = sign + hours + ":" + minutes;
  // console.log('formattedTimeZone', formattedTimeZone)
  return formattedTimeZone;
}

export function getTz() {
  return timezone.tz.guess();
}

