import { createApp, h, ref } from "vue";
import App from "./App.vue";
import router from "./router";
import VueI18n from "./language";
import store from "./store";
import Element from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "element-plus/dist/index.css";
import "@/svgIcon/index.js";
import svgIcon from "@/svgIcon/index.vue";
import "@/assets/fonts/font.scss"; // 引入字体样式
import "@fortawesome/fontawesome-free/css/all.css"; // 引入 Font Awesome CSS
import storage from "@/utils/storage";

import vueEsign from 'vue-esign';
import { Vue3Mq } from "vue3-mq";
import VueGoogleMaps from "@fawmi/vue-google-maps";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueI18n)
  .use(Element, {})
  .use(vueEsign)
  .use(Vue3Mq, { preset: "tailwind", global: true })
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyCVrIO7GVMea5V4Q8muOYP0LiqbnDMVZtI"
    }
  })
  .provide("$storage", storage)
  .component("svgIcon", svgIcon);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// 挂载自定义的消息对话框
import { eventBus } from '@/utils/event_bus';
import MessageBox from '@/components/MessageBox';

// 创建 Dialog 实例
const dialogContainer = document.createElement('div');
document.body.appendChild(dialogContainer);

const dialogApp = createApp({
  components: { MessageBox },
  setup() {
    const visible = ref(false);
    const message = ref('');
    const title = ref('');
    const type = ref('');
    const options = ref(null);
    let callback = null;

    eventBus.value.showDialog = (_message, _title, _type, _options, _callback) => {
      message.value = _message;
      title.value = _title;
      type.value = _type;
      options.value = _options;
      visible.value = true;
      callback = _callback;
    };

    const hideDialog = (val) => {
      visible.value = false;
      callback && callback(val);
    };

    return {
      visible,
      message,
      title,
      type,
      options,
      hideDialog
    };
  },
  render() {
    return h(MessageBox, {
      visible: this.visible,
      title: this.title,
      message: this.message,
      type: this.type,
      options: this.options,
      'onUpdate:visible': () => {
        this.hideDialog('close');
      },
      onCancel: () => {
        this.hideDialog('cancel');
      },
      onConfirm: () => {
        this.hideDialog('confirm');
      }
    });
  }
}).use(store);

dialogApp.mount(dialogContainer);

router.isReady().then(() => {
  app.mount("#app");
});
