export default class Location {
  static getLocation(callback) {
    if (navigator.geolocation) {
      let options = {
        enableHighAccuracy: true,
        maximumAge: 1000
      };

      navigator.geolocation.getCurrentPosition(
        (res) => {
          //   console.log("get location successful:" + res);
          let location = {};
          location.longitude = res.coords.longitude;
          location.latitude = res.coords.latitude;
          callback.success(location);
        },
        (res) => {
          //   console.log("get location failed:" + res);
          callback.error(res);
        },
        options
      );
    } else {
      callback.error("Geo location not supported.");
    }
  }

  static getLocale() {
    const systemLanguage = navigator.language || navigator.userLanguage;
    // console.log("systemLanguage", systemLanguage);
    if (systemLanguage === 'zh-HK') {
      // 处理香港地区的语言设置
      return 'hk';
    } else {
      return systemLanguage.split('-')[0];
    }
  }

  static languageMap = {
    "en": "01",
    "zh": "02",
    "ko": "01", // "03"
    "ja": "01", // "04"
    "hk": "05",
    "id": "06",
    "th": "01", // "07"
    "vi": "01", // "08"
  }

  static getLanguageType() {
    const languageType = this.languageMap[this.getLocale()];
    return languageType || "01";
  }
}