const state = {
  languageType: '', // 语言种类
  locale: "en", 
  theme: "light", // auto, dark, light
  token: undefined,
};

const mutations = {
  SET_LANGUAGE_TYPE(state, languageType) {
    state.languageType = languageType;
  },
  SET_LOCALE: (state, locale) => {
    state.locale = locale;
  },
  SET_THEME: (state, theme) => {
    state.theme = theme;
  },
  TOKEN(state, obj) {
    state.token = obj;
  },
};

const actions = {
  setLanguagetype({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_LANGUAGE_TYPE", params);
      resolve();
    });
  },
  // 修改语言
  changeLocale({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_LOCALE", params);
      resolve();
    });
  },
  // 修改主题
  changeTheme({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_THEME", params);
      resolve();
    });
  },
  setToken({ commit }, params) {
    return new Promise((resolve) => {
      commit("TOKEN", params);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
